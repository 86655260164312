@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/utils.scss";
@import "../../styles/forms.scss";


.test-group {

  &.closed > .children {
    display: none;
  }

  &.hidden {
    display: none;
  }

  .test-group {
    margin-left: $lineHeight;
  }

  .test {
    margin-left: $lineHeight * 2;
  }

  .run-button, .run-button:hover {
    color: #06C;
    background: #f3f3f3;
    font-weight: 400;
    padding: 0px 8px !important;
    opacity: 0;
    transition: opacity 0.2s linear 0.1s;
    position: absolute;
    right: 5px;
    top: 2px;
  }
}

.test-group-header {
  font-weight  : bold;
  border-radius: 4px;
  cursor       : pointer;
  user-select  : none;
  white-space  : nowrap;
  text-overflow: ellipsis;
  border       : 1px solid transparent;
  color        : #000;
  position: relative;
  line-height  : $lineHeight;
  padding      : 1px 2px;
  margin       : 1px 0;

  &:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
  }

  .fa-folder,
  .fa-folder-open {
    text-align: left;
    color: rgb(191, 152, 45);
  }
}


.test-group > header .run-button,
.test.closed .run-button {
  visibility: hidden;
}
.test-group header:hover {
  background: #f3f3f3;
  .run-button {
    visibility: visible;
    opacity: 1;
  }
}

.tests-header {
  background   : #FFF linear-gradient(#fff, $colorLightGrey);
  flex: auto;
  white-space: nowrap;

  select {
    border-color: $colorBrandDarker;
    color: $colorBrandDarker;
  }
}

