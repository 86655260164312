@import "../../styles/variables.scss";

.server-form {
    padding: 0;
    display: flex;
    flex-direction: column;

    fieldset {
        border: 0 none;
    }

    .black {
        color: #000;
    }

    .sql-editor {
        height: 480px;
        width: 100% !important;
        min-height: 100px;
        min-width: 100px;
        display: block;
        flex: 1 0 100px;
        border: 1px solid #CCC;
        background: #FFF;
        overflow: hidden;
        position: relative;
        z-index: 2;
        pointer-events: all;

        .monaco-editor {
            height: 100% !important;
            width: 100% !important;
            .overflow-guard {
                height: 100% !important;
                width: 100% !important;
            }
        }

        *:focus {
            box-shadow: none !important;
        }
    }

    .form-group {
        min-width: 250px;
    }

    .nav-tabs {
        margin     : 0px 0px 20px;
        padding    : 5px 20px 0 20px;
        white-space: nowrap;
    }

    h3 {
        background: $colorBrandDark;
        color: #FFF;
        padding: 0.5em 1em;
        margin: 0 0 2rem;
    }

    hr {
        margin: 2rem 0;
    }

    .error, #server-form-error {
        color        : $colorErrorFg;
        background   : $colorErrorBg;
        padding      : 0.5em 1em;
        border-radius: 0.25em;
        margin       : 0 0 20px 0;

        &:empty {
            display: none;
        }
    }

    label .small.btn.btn-sm.btn-secondary {
        line-height: 0;
        vertical-align: top;
        font-weight: normal;
        font-size: 13px;
    }
}
