.server-table {
    table-layout   : fixed;
    min-width      : 100%;
    border-spacing : 1px;
    font-size      : 13px;
    border-collapse: separate;
    background     : #CCC;

    th {
        text-align    : center;
        vertical-align: middle;
        font-weight   : 500;
        padding       : 4px;
        overflow      : hidden;
        text-overflow : ellipsis;

        &:first-child {
            width: 30vw;
        }
    }

    td {
        text-align: center;
        vertical-align: middle;
        color: #BBB;
        background: #EEE;
    }

    thead th {
        background: #EEE;
        position: sticky;
        z-index: 200;
        top: 20px;
        box-shadow: 0 0 0 1px #CCC, 0 -20px 0 1px #FFF;

        &:empty {
            background: #FFF;
        }

        a {
            color: #369;
            text-shadow: 0 1px 0 #FFF;
            font-weight: 600;
        }
    }

    tbody {

        tr.group {
            > th {
                font-weight: 600;

                &:before {
                    content: "";
                    display: inline-block;
                    width  : 0;
                    height : 0;
                    border-width: 5px 5px 0 5px;
                    border-color: #000 transparent transparent transparent;
                    border-style: solid solid none solid;
                    margin: -1px 3.5px 1px -13.5px;
                }
            }

            &.collapsed {
                > th:before {
                    content: "";
                    display: inline-block;
                    width  : 0;
                    height : 0;
                    border-width: 5px 0 5px 5px;
                    border-color: transparent transparent transparent #000;
                    border-style: solid none solid solid;
                    margin: 0 6px 0 -11px;
                }
            }
        }

        tr {
            background: #FFF;
            &:hover {
                background: #EEE;
            }
        }
        th {
            text-align: left;
            width: auto;
            min-width: 300px;
            font-weight: normal;
            cursor: default;
        }
        td {
            background: rgba(255, 255, 255, 0.2);

            &.succeeded {
                background: rgba(0, 153, 0, 0.1);
            }

            &.failed {
                background: rgba(255, 125, 125, 0.147);
            }
            
            &.not-implemented {
                background: rgba(255, 136, 0, 0.2);
            }
            
            &.not-supported {
                background: rgba(255, 255, 255, 0.2);
            }

            &.warned {
                background: linear-gradient(
                    135deg,
                  rgba(0, 153, 0, 0.1) 25%,
                  rgba(254, 152, 56, 0.3) 25%,
                  rgba(254, 152, 56, 0.3) 50%,
                  rgba(0, 153, 0, 0.1) 50%,
                  rgba(0, 153, 0, 0.1) 75%,
                  rgba(254, 152, 56, 0.3) 75%,
                  rgba(254, 152, 56, 0.3) 100%
                );
                background-size: 6.00px 6.00px;
            }

            &:not([colspan]):hover {
                position: relative;
                z-index: 2;
                box-shadow: 0 0 0 1px #666;
                .tooltip {
                    display: block;
                }
            }
        }
    }
}