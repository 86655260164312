@use "sass:math";
@import "../../styles/variables.scss";

.app-footer {
    background: $colorLightGrey;
}

.app-footer-inner {    
    padding   : 5px $gridGap;
    max-width : $maxWidth;
    margin    : 0 auto;
    color     : $colorBrandDarker;
    text-align: center;
    display   : flex;
    height    : 40px;
    justify-content: center; 
    align-content: center;
    flex-wrap: wrap;

    a {
        color: $colorBrandDarker;
        display: flex;
        padding: math.div($gridGap, 4) $gridGap;
        align-items: center;
        font-size: 90%;
        max-height: 2em;

        &:hover {
            color: blue;
        }
    }
}
