@import "./variables.scss";

.bold {
    font-weight: bold;
}
.italic {
    font-style: italic;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}

.float-left {
    float: left;
}
.float-right {
    float: right;
}

.d-inline-block {
    display: inline-block;
}
.v-top {
    vertical-align: top;
}
.color-danger {
    color: #bb0000;
}
.color-warning {
    color: #c27e00;
}
.color-brand {
    color: $colorBrand;
}
.color-brand-dark {
    color: $colorBrandDark;
}
.color-brand-darker {
    color: $colorBrandDarker;
}
.color-muted {
    color: #888;
}

.display-hover {
    display: none;
}
*:hover > .display-hover {
    display: initial;
}

.ellipsis {
    max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alert {
    padding: 1ex 1em;
    border-radius: 3px;
    margin-bottom: 1em;
    background: #EEE;
    border: 1px solid #CCC;
    color: #333;

    &.alert-danger {
        background: rgb(255, 229, 229);
        color: rgb(177, 0, 0);
        border-color: #f3dada;

        hr {
            background: rgba(177, 0, 0, 0.295);
        }
    }

    &.alert-success {
        background: rgb(230, 255, 229);
        color: rgb(0, 119, 10);
        border-color: rgb(197, 235, 196);
        padding: 4ex 4em;
    }

    &.alert-info {
        background: rgb(229, 249, 255);
        color: rgb(36, 107, 157);
        border-color: rgb(217, 238, 245);
        padding: 1ex 1em;
    }
}
