@import "./functions.scss";

@mixin button($bg: $colorBrandDark, $hoverBg: darken($bg, 5%)) {
    $paddingTop    : 5px;
    $paddingRight  : 15px;
    $paddingBottom : 5px;
    $paddingLeft   : 15px;
    $contrast      : 85;
    $color         : contrast($bg, 50, lighten($bg, $contrast), darken($bg, $contrast));

    background     : $bg linear-gradient(lighten($bg, 5%), darken($bg, 5%));
    border         : 1px solid darken($bg, 15%);
    line-height    : inherit;
    font-size      : inherit;
    color          : $color;
    border-radius  : 3px;
    vertical-align : middle;
    text-decoration: none;
    cursor         : default;
    display        : inline-block;
    min-height     : 4ex;
    outline        : 0;
    user-select    : none;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding        : $paddingTop $paddingRight $paddingBottom $paddingLeft;
    text-shadow    : contrast-text-shadow($color);
    box-shadow     : 0 0 1px lighten($bg, 10%) inset,
                     0 2px 0 -1px lighten($bg, 10%) inset;
  
    &:hover:not(:disabled) {
        $color      : contrast($hoverBg, 40, lighten($hoverBg, $contrast), darken($hoverBg, $contrast));
        border-color: rgba(0, 0, 0, 0.2);
        background  : $hoverBg linear-gradient(lighten($hoverBg, 5%), darken($hoverBg, 5%));
        color       : $color;
        text-shadow : contrast-text-shadow($color);
    }

    &:focus:not(:disabled) {
        border-color: rgba(0, 0, 0, 0.3);
    }
    &:focus-visible:not(:disabled) {
        box-shadow  : 0 0 0 3px rgba(37, 139, 255, 0.3);
    }
    
    &:active:not(:disabled), &.active:not(:disabled) {
        $color      : contrast($hoverBg, 50, lighten($hoverBg, $contrast), darken($hoverBg, $contrast));
        border-color: rgba(0, 0, 0, 0.3);
        background  : darken($hoverBg, 5);
        color       : $color;
        box-shadow  : 5px 8px 5px -5px rgba(0, 0, 0, 0.2) inset;
        text-shadow : contrast-text-shadow($color);
        padding     : ($paddingTop + 1) ($paddingRight - 1) ($paddingBottom - 1) ($paddingLeft + 1);
        // &:focus-visible {
        //     box-shadow: 5px 8px 5px -5px rgba(0, 0, 0, 0.2) inset, 0 0 0 3px rgba(37, 139, 255, 0.3);
        // }
    }

    // &:active:not(:disabled) {
    //     box-shadow: 5px 8px 5px -5px rgba(0, 0, 0, 0.2) inset, 0 0 0 3px rgba(37, 139, 255, 0.3);
    // }

    &:disabled {
        opacity       : 0.5;
        filter        : grayscale(1);
        pointer-events: none;
    }
}