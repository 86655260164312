$fontSize  : 18px;
$fontSizeMd: 14px;
$fontSizeSm: 12px;
$lineHeight: 1.4em;
$fontFamilyMono: Menlo, source-code-pro, Monaco, Consolas, "Courier New", monospace;

$gridGap : 18px;
$maxWidth: 1600px;

// Colors
$colorBrand    : #84a4c7;
$colorBrandDark: darken($colorBrand, 20%);
$colorBrandDarker: darken($colorBrand, 40%);
$colorSuccessFg: #090;
$colorSuccessBg: #cbf3b8;
$colorInfoFg   : #4086b5;
$colorInfoBg   : #e6f5ff;
$colorErrorFg  : #900;
$colorErrorBg  : #f7e4e4;
$colorWarningFg: #aa6000;
$colorWarningBg: #fff5d9;
$colorGrey     : #7e7d7b;
$colorLightGrey: #e1e1e0;