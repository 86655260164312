.nav-tabs {
    border-bottom: 1px solid #CCC;
    padding      : 5px 0 0 1ex;
    background   : #FFF;
    display      : flex;
    flex-wrap    : nowrap;

    > * {
        flex         : 1 1 auto;
        max-width    : fit-content;
        display      : inline-block;
        border-radius: 4px 4px 0 0;
        padding      : 1ex 2%;
        color        : #666;
        cursor       : default;
        user-select  : none;
        border       : 1px solid transparent;
        margin       : 0 1px -1px;
        position     : relative;
        overflow     : hidden;
        text-overflow: ellipsis;
        text-align   : center;

        &:hover {
            background-color   : rgba(127, 127, 127, 0.1);
            border-bottom-color: #CCC;
        }
        
        &.active {
            border-left-color  : #CCC;
            border-top-color   : #CCC;
            border-right-color : #CCC;
            border-bottom-color: #FFF;
            box-shadow         : 0 3px 9px #FFF inset, 0 3px 0 #ff9800 inset, 0 1px 0 #FFF;
            z-index            : 2;
            color              : #000;
            background-color   : #FFF;
        }
    }
}