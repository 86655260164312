@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/utils.scss";
@import "../../styles/forms.scss";


.run-all-btn {
  i {
    font-size: 13px;
  }

  &.running {
    background-color: $colorBrandDark !important;
  }
}


.tests-toolbar {
  background   : #FFF linear-gradient(#fff, #E0E0E0);
  flex: auto;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.075) inset;

  .toolbar {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    max-width: 100%;
  }

  .col {
    flex: 1 1 auto;
    padding: 5px;
  }

  .toolbar select {
    border-color: $colorBrandDark;
    &, & option {
      color: $colorBrandDarker;
    }
  }
}

.test-suite {
  flex: 1 1 100%;
  overflow: auto;
  overflow: overlay;
}

.home-screen {
    h1 {
      margin: 1em 0 0;
    }

    > div {
        display: grid;
        gap: 1em;
        margin: 0;
        padding: 0;
        grid-template-columns: repeat(2, 50%);
        
        > div {
            margin: 2em;
            background: linear-gradient(149deg, #9100ff1a, #007dff30 600px, #55ff0024);
            background-attachment: fixed;
            border-radius: 1em;
            padding: 1em 2em;
            text-align: center;
        }
    }
}