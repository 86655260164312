@use "sass:math";
@import "../../styles/variables.scss";

.test {
    border-radius: math.div($fontSize, 4);
    border       : 1px solid transparent;
    margin       : 0 0 1px;
    color        : #666;
    scroll-margin: 5px;

    .test-header {
        border-radius: math.div($fontSize, 4) - 0.5;
        cursor       : pointer;
        user-select  : none;
        white-space  : nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight  : 600;
        line-height  : $lineHeight;
        padding      : 2px 2px;
        position: relative;
        

        &:after {
            content: "";
            display: block;
            clear  : both;
            height : 0;
        }
    }

    

    .children {
        margin       : 0;
        padding      : 0;
        border-radius: 0 0 math.div($fontSize, 5) math.div($fontSize, 5);
        overflow     : hidden;

        p {
            margin: 0.5ex auto;
        }

        ul, ol {
            margin: 0;
            padding: 0;
            list-style-position: inside;

            ul, ol {
                padding-left: 1.5em;
            }
        }
    }

    pre {
        border       : 0;
        padding      : 0;
        background   : transparent;
        border-radius: 0;
        white-space  : pre-wrap;
        word-break   : break-all;
        text-shadow  : 0 0 0.5px;

        code {
            background: transparent;
            border    : 0;
        }
          
        th {
            vertical-align: text-top;
            text-align    : right;
            color         : rgb(92, 38, 153);
            white-space   : nowrap;
        }
        
        td {
            vertical-align: top;
            white-space   : normal;
            word-wrap     : break-word;
            word-break    : break-all;
            color         : rgb(196, 26, 22);
        }
    }

    &:hover {
        background: #f3f3f3;

        .run-button {
            visibility: visible;
            opacity: 1;
            background: #f3f3f3;
        }
    }

    &.opened {
        background: #f3f3f3;
        margin-bottom: 1em;

        .test-header {
            color: #000;
        }
    }

    &.not-supported {
        
        .test-header {
            color: #888;

            .run-button {
                display: none;
            }
        }

        .test-info.warning {
            font-weight: bold;
        }
    }

    &.hidden {
        display: none;
    }

    &:focus-visible, &:target:focus {
        outline: 1px solid $colorBrandDark;
        outline-offset: -1px;
    }
}

.test-info {
    padding: 0.5em 1em 0.5em 30px;
  
    + .test-info {
        border-top: 1px dotted rgba(0, 0, 0, 0.15); 
    }

    > div > b > .fas.fa-caret-right,
    > div > b > .fas.fa-caret-down {
        width: 0.7em;
    }

    &.footer {
        color: #888;
        .text-muted {
            color: #CCC;
        }
    }

    &.error {
        color      : $colorErrorFg;
        background : $colorErrorBg;
        font-family: $fontFamilyMono;
        font-size  : 0.9em;
    }

    &.warning {
        color      : $colorWarningFg;
        background : $colorWarningBg;
    }

    &.info {
        color      : $colorInfoFg;
        background : $colorInfoBg;
    }
}

// editor ----------------------------------------------------------------------
.editor {
    font-size: small;
  
    + .editor {
        border-top: 1px dotted rgba(0, 0, 0, 0.2);
        margin-top: 8px;
        padding-top: 8px;
    }
    
    label {
        margin: 1ex 0;
        font-weight: bold;
    }
    
    .description {
        color: #666;
        margin: 1ex 0;
        font-size: smaller;
    }
    
    input {
        display      : block;
        border-radius: 2px;
        border       : 1px solid rgb(40, 104, 129);
        padding      : 2px 4px;
        font         : inherit
    }
}
  