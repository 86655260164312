.container {
    padding       : 0 1rem;
}
.row {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    margin        : 0 -1rem;
}

.col {
    display       : flex;
    flex-direction: column;
    padding       : 0 1rem;
    flex          : 1 1 auto;

    &:after {
        content: "";
        display: block;
        clear  : both;
        // height : 1em;
    }
}

.grid-row {
    display: grid;
    gap: 0;
    margin: 0;
    padding: 0;
}

.grid-col {
    grid-template-columns: repeat(1, 100%);
    padding: 1ex;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

@media (min-width: 700px) {
    .grid-row {
        &.c2 { grid-template-columns: repeat(2, 50%); }
        &.c3 { grid-template-columns: repeat(3, 33.33%); }
        &.c4 { grid-template-columns: repeat(4, 25%); }

        &.c2.auto { grid-template-columns: repeat(2, auto); }
        &.c3.auto { grid-template-columns: repeat(3, auto); }
        &.c4.auto { grid-template-columns: repeat(4, auto); }
    }
}