@use "sass:math";
@import "../../styles/variables.scss";

.app-header {
    top    : 0;
    left   : 0;
    width  : 100%;
    height : 54px;
    z-index: 2000;

    > a {
        text-decoration: none !important;
    }
}

a .app-logo {
    padding    : math.div($gridGap, 2) - 2px $gridGap 0;
    max-width  : $maxWidth;
    margin     : auto;
    font-family: 'Roboto Condensed', 'Arial Narrow', Arial, sans-serif;
    font-weight: 600;
    float      : left;
    color      : rgba(255, 255, 255, 0.75);

    > img {
        height: 38px;
        vertical-align: top;
        margin-right: 0.5ex;
    }
}
.app-navbar {
    background: linear-gradient(90deg, #006856, $colorBrandDark);
    text-align: center;

    .app-navbar-inner {
        padding    : 9px $gridGap 0;
        max-width  : $maxWidth;
        margin     : auto;
        white-space: nowrap;

        > a {
            padding        : 13px 2vw;
            display        : inline-block;
            border-radius  : 5px 5px 0 0;
            margin-right   : 6px;
            color          : $colorLightGrey;
            text-decoration: none;
            font-weight    : bold;
            text-transform : uppercase;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }

            &.active {
                background: #FFF;
                color     : rgb(34, 58, 83);
            }
        }
    }

    .auth-button {
        line-height: 32px;
        color: rgba(255, 255, 255, 0.3);

        b, a {
            color: rgba(255, 255, 255, 0.7);
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}