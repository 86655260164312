.tooltip {
    position      : absolute;
    z-index       : 2;
    background    : #FFF;
    top           : 100%;
    left          : 50%;
    margin        : 7px 0 0;
    width         : max-content;
    min-width     : 100%;
    border        : 1px solid #999;
    display       : none;
    padding       : 10px;
    transform     : translateX(-50%);
    box-shadow    : 0 2px 3px rgba(0, 0, 0, 0.2);
    border-radius : 4px;
    pointer-events: none;
    max-width     : 50vw;
    color         : #444;
    text-align    : left;
    word-break: break-all;


    &:before {
        content     : "";
        position    : absolute;
        width       : 0;
        height      : 0;
        top         : 0;
        left        : 50%;
        margin      : -6.5px 0 0 -6px;
        border-width: 0 6px 6px 6px;
        border-style: none solid solid solid;
        border-color: transparent transparent #999 transparent;
    }

    &:after {
        content     : "";
        position    : absolute;
        width       : 0;
        height      : 0;
        top         : 0;
        left        : 50%;
        margin      : -5px 0 0 -5px;
        border-width: 0 5px 5px 5px;
        border-style: none solid solid solid;
        border-color: transparent transparent #FFF transparent;
    }

    &.top {
        top   : auto;
        bottom: 100%;
        margin: 0 0 7px;

        &:before {
            top         : auto;
            bottom      : -6.5px;
            left        : 50%;
            margin      : 0 0 0 -6px;
            border-width: 6px 6px 0 6px;
            border-style: solid solid none solid;
            border-color: #999 transparent transparent transparent;
        }

        &:after {
            top         : auto;
            bottom      : -5px;
            left        : 50%;
            margin      : -5px 0 0 -5px;
            border-width: 5px 5px 0;
            border-style: solid solid none;
            border-color: #FFF transparent transparent;
        }
    }

    &.left {
        left        : auto;
        right       : 50%;
        transform   : translateX(0);
        margin-right: -26px;

        &:before {
            left : auto;
            right: 19px;
        }

        &:after {
            left: auto;
            right: 20px;
        }
    }
}
