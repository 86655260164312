@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/utils.scss";
@import "../../styles/forms.scss";



.modal-root {
  background     : rgba(0, 0, 0, 0.3);
  position       : fixed;
  top            : 0;
  left           : 0;
  right          : 0;
  bottom         : 0;
  z-index        : 3001;
  display        : flex;
  flex-direction : column;
  flex           : 1 1 0;
  align-content  : center;
  align-items    : center;
  justify-content: center;
  transform      : scale3d(1, 1, 1);
  transition     : background 0.2s linear 0.11s, transform 0.1s;

  &:empty {
    pointer-events: none;
    transform     : scale3d(0, 0, 0);
    background    : rgba(0, 0, 0, 0);
    transition    : background 0.2s, transform 0.1s linear 0.21s;
  }
}
.modal {
  border-radius: 5px;
  max-width    : 80vw;
  box-shadow   : 0 0 0 1px rgba(0, 0, 0, 0.2);
  
}

.modal-root .modal {
  box-shadow   : 0 0 0 1px rgba(0, 0, 0, 0.2),
                 0 5px 15px -1px rgba(0, 0, 0, 0.5);
}

.modal-header {
  background   : #EEE linear-gradient(#EEE, #DDD);
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #CCC;
  padding      : 10px 20px;
  font-weight  : bold;
  text-shadow  : 0 1px 0 #FFF;
  box-shadow   : 0 1px 1px 0px #FFF inset;

  .dialog-close-btn {
    float: right;
    margin: -1px -12px 0 0;
    color: #333;
    cursor: pointer;
    opacity: 0.5;
    text-shadow: none;

    &:hover {
      color: #C00;
      opacity: 1;
    }
    &:active {
      color: #900;
    }
  }
}

.modal-body {
  background: #FFF;
  padding   : 20px;
  max-width : 80vw;
  max-height: 80vh;
  overflow  : auto;
  overflow  : overlay;
}

.modal-footer {
  background   : #F6F6F6;
  border-radius: 0 0 5px 5px;
  border-top   : 1px solid #CCC;
  padding      : 10px 20px;
  box-shadow   : 0 1px 10px 0px #EEE inset,
                 0 -1px 1px 0px #FFF inset;
}


