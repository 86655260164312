.progress-wrapper {
    text-align: left;

    .progress {
        background: #EEE;
        height: 2ex;
        position: relative;
        overflow: hidden;
        clear: both;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }

    .progress-inner-tick {
        $box-shadow: 0 0 0px 0.5px rgba(255, 255, 255, 0.667) inset;
        box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.05) inset;
        height: 100%;
        position: absolute;

        // failed
        &._0 { background: #c84500; box-shadow: $box-shadow; }
        
        // succeeded
        &._1 { background: #019900; box-shadow: $box-shadow; }
        
        // not implemented
        &._2 { background: #ea9000; box-shadow: $box-shadow; }
        
        // skipped
        &._3 { background: #92b4d2; box-shadow: $box-shadow; }

        // aborted
        &._4 { background: #f19b46; box-shadow: $box-shadow; }

        // warned
        &._5 {
            background: linear-gradient(
                45deg,
                #ea9000 0%,
                #ea9000 20%,
                #666666 25%,
                #ea9000 30%,
                #ea9000 70%,
                #666666 75%,
                #ea9000 80%,
                #ea9000 100%,
            );
            background-size: 3px 3px;
            box-shadow: $box-shadow; }

        // not supported
        &._6 { 
            background: linear-gradient(
                45deg,
                #CCC 0%,
                #BBB 25%,
                #CCC 50%,
                #BBB 75%,
                #CCC 100%
            );
            background-size: 3px 3px;
            box-shadow: $box-shadow;
        }

        // running
        &._7 {
            background: #FFF;
        }

        // unknown
        &._8 {
            background: transparent;
        }

        &._none {
            background: transparent;
        }

        &:first-child {
            border-top-left-radius   : 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child {
            border-top-right-radius   : 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .pct {
        float: right;
    }
    
    .msg {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        font-size: 14px;
        padding-right: 7em;

        .fa-spin {
            font-size: 10px;
            vertical-align: baseline;
            position: relative;
            top: -1.5px;
            color: #036;
        }
    }
}