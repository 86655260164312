@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/utils.scss";
@import "../../styles/forms.scss";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  display: block;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #444;
}

::selection {
  background: #369;
  color: #FFF;
  text-shadow: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

p {
  margin: 0.5ex 0;
}

a {
  color: #39C;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.small {
  font-size: small;
}

select {
  -moz-appearance: none;
}

select:before {
  content: "⯆";
  display: inline;
}

code, pre, textarea {
  font-family: Menlo, source-code-pro, Monaco, Consolas, "Courier New", monospace;
}

textarea {
  font-size: 11px;
  white-space: pre;
  resize: vertical;
}

code {
  border       : 1px solid rgba(0, 0, 0, 0.096);
  border-radius: 3px;
  background   : rgba(255, 255, 255, 0.753);
  color        : #e83e8c;
  font-size    : 94%;
}

pre {
  color        : #446;
  font-size    : 0.9em;
  white-space: pre-wrap;
}

pre pre, pre code {
  font-size: inherit;
}

pre code {
  border       : 0;
  border-radius: 0;
  background   : transparent;
  color        : unset;
}

.container {
  display  : block;
  max-width: 1600px;
  padding  : $gridGap;
  margin   : 0 auto;
  width    : 100%;
}

#root {
  display       : flex;
  flex-direction: column;
  height        : 100vh;
}

.app {
  color         : #333;
  width         : 100%;
  background    : #FFF;
  overflow      : auto;
  display       : flex;
  flex          : 1 1 0;
  flex-direction: column;
}

.app-inner {
  padding  : $gridGap;
  // max-width: $maxWidth;
  width: 100%;
  margin   : auto;
  flex: 1 1 100%;
  overflow: auto;
}

hr {
  background: linear-gradient(90deg, #006856, $colorBrandDark);
  opacity: 0.3;
  border: 0;
  height: 2px;
  width: 100%;

  &.light {
    background: #DDD;
    height: 1px;
    margin: 5px 0;
  }
}

.text-muted {
  color: #666;
}

.force-wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

.fas, .far {
  width      : $lineHeight;
  height     : $lineHeight;
  text-align : center;
  line-height: $lineHeight;
}

.separator {
  display: inline-block;
  vertical-align: text-top;
  height: 1.5em;
  margin: 0.25em 0.45em;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.9);
}
