@import "../../styles/variables.scss";


.server-info-entry {
    color: #666;
    padding: 1em;
    margin-bottom: 1em;
    background: #f3f3f3;
    border-radius: 1ex;
    word-wrap: break-word;

    .private & {
        background: #fdebeb;
    }

    .local & {
        background: #faf3d2;
    }

    h3 {
        margin-bottom: 1ex;
        padding-bottom: 1ex;
        color: #333;
        border-bottom: 1px solid rgba(127, 127, 127, 0.15);

    }

    .server-info-footer {
        margin-top: 1ex;
        padding   : 1ex 0 0;
        border-top: 1px solid rgba(127, 127, 127, 0.15);
    }

    table {
        table-layout: fixed;
        width: 100%;
        font-size: 90%;
        th {
            width: auto;
            text-align: right;
            vertical-align: top;
            white-space: nowrap;
            width: 9em;
        }
        td {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .admin-buttons {
        display: none;
    }

    &:hover .admin-buttons {
        display: block;
    }
}

.modal-body .server-form {
    min-height: 100%;

    .nav-tabs {
        position: sticky;
        top: -20px;
        background: #FFF;
        z-index: 5;
        margin: -20px -20px 20px;
        padding: 5px 20px 0 20px;
        background: #F9F9F9;
        white-space: nowrap;
    }

    .sql-editor {
        height: auto;
        transition: opacity 0.4s, filter 0.4s;
    }

    :disabled .sql-editor {
        opacity: 0.8;
        pointer-events: none;
        filter: grayscale(1);
    }
}
