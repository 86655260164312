@import "./mixins.scss";
@import "./variables.scss";

input[type="text"],
input[type="url"],
input[type="password"],
input[type="email"],
select,
textarea {
    background: #FFF;
    border: 1.5px solid mix($colorBrand, #EEE);
    border-radius: 4px;
    padding: 5px 10px;
    outline: none;
    font: inherit;

    &:focus {
        border-color: rgb(37, 139, 255);
    }
    &:focus-visible {
        box-shadow: 0 0 0 3px rgba(37, 139, 255, 0.3);
    }

    &:invalid {
        border-color: rgb(204, 41, 0);  
    }

    &:read-only {
        color: #777;
        border-color: #CCC;
    }

    &:disabled {
        background: #EEE;
        border-color: #CCC !important;
        color: #888;
        text-shadow: 0 1px 0 #FFF;
        box-shadow: 0 0 0 1px #FFF inset;
    }
}

select {
    background: #FFF url('caret-down.svg') 100% center no-repeat;
    background-size: auto;
    padding-right: 18px;
    background-size: 22px 14px;
    appearance: none;
}

textarea:disabled {
    // fix a bug in Chromium/Linux
    filter: grayscale(1);
}

::placeholder,
::-webkit-input-placeholder {
    color: #999;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 1px 4px 1px 0px;
  padding: 1px;
  vertical-align: text-bottom;
}

.checklist {
    
    .checklist-title {
        display: block;
        font-weight: bold;
        margin: 0 0 0.25em;
    }
    .checklist-item {
        padding: 0 0 0 1.6em;
        display: block;
        margin: 0;
        line-height: normal;

        input[type="checkbox"], input[type="radio"] {
            margin: -0.075em 0.4em 0.075em -1.6em;
            width: 1em;
            height: 1em;
            line-height: 0;
            vertical-align: middle;

            &:disabled + *,
            &:disabled + * * {
                color: #999;
            }
        }
    }
}

.form-description {
    color: #999;
    font-size: 82%;
    margin-bottom: 0.4rem;
}

.form-group {
    margin-bottom: 1em;

    &:last-of-type {
        margin-bottom: 0    
    }

    label {
        display: block;
        margin-bottom: 0.25rem;
        color: $colorBrandDark;
    }

    input, textarea {
        display: block;
        width: 100%;
        font-size: inherit;
        font-family: inherit;
    }

    input[type="checkbox"], input[type="radio"] {
        display       : inline-block;
        line-height   : inherit;
        vertical-align: top;
        width         : 16px;
        height        : 16px;
        margin        : 3px 4px 0 0;
        padding       : 0;

        + .d-inline-block {
            max-width: calc(100% - 16px);
        }
    }
}

.btn {

    &:disabled, &.disabled {
        filter: grayscale(1);
        pointer-events: none;
        opacity: 0.5;
    }

    white-space: nowrap;

    + .btn {
        margin-left: 1ex;
    }

    &.btn-sm {
        font-size: small;

        padding: 2px 8px !important;
        min-height: 0 !important;

        &:not(:disabled) {
            &:active, &.active {
                padding: 3px 7px 1px 7px !important;
            }
        }
    }

    &.btn-primary {
        @include button($colorBrandDark);
    }
    &.btn-brand {
        @include button($colorBrand);
    }
    &.btn-warning {
        @include button(rgb(255, 166, 0));
    }
    &.btn-secondary {
        @include button(#EEE);
    }
    &.btn-danger {
        @include button(rgb(211, 49, 0));
    }
    &.btn-success {
        @include button(rgb(55, 151, 10));
    }
}

.btn-group {
    .btn {
        display: inline-block;
        border-radius: 0;

        &:first-child {
            border-radius: 3px 0 0 3px;
        }
        &:last-child {
            border-radius: 0 3px 3px 0;
        }

        + .btn {
            margin-left: -1px;
        }

        &:hover, &:active, &.active, &:focus {
            position: relative;
            z-index: 2;
        }
    }
}